import {Component, ElementRef, OnInit} from '@angular/core';
import {SpinnerService} from "./service/spinner.service";
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from "@angular/router";
import { enableProdMode } from '@angular/core';
import {E2EService} from "./service/e2e.service";
import {AuthenticationService} from "./service";
import {CommonService} from "./service/common.service";
import {LanguageService} from "./service/language.service";

enableProdMode();
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'tcc-web';
  constructor(private router: Router,  private languageService : LanguageService, public spinner: SpinnerService,public commonService:CommonService ,private e2eService:E2EService,private authenticationService:AuthenticationService) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.spinner.show();
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.spinner.hide();
      }
    }, () => {
      this.spinner.hide();
    });
    this.languageService.setInitialAppLanguage();
  }

  async ngOnInit()  {

    if (this.authenticationService.isAuthenticated()) {
      this.e2eService.loadPrivateKey();
    }
  }
}
