import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
// import {Router} from "@angular/router";
import { ApiService, AuthenticationService } from '../../service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/service/common.service';
import { SpinnerService } from 'src/app/service/spinner.service';
import {
  EMAIL_PATTERN,
  PHONE_PATTERN,
  NAME_PATTERN,
} from 'src/app/helpers/validations';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import { PASSWORD_PATTERN } from '../../helpers/validations';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-common-layout',
  templateUrl: './new-common-layout.component.html',
  styleUrls: ['./new-common-layout.component.scss'],
})
export class NewCommonLayoutComponent implements OnInit {
  modalRef: BsModalRef;
  modalRef1: BsModalRef;
  modalRef2: BsModalRef;
  authDetail: any = [];
  showMenu: boolean = true;
  currentYear: any;

  profileForm: FormGroup;
  @ViewChild('profilePic') profilePic: ElementRef;
  allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
  url = '';
  passwordForm: FormGroup;
  showPassword1: boolean = false;
  showPassword2: boolean = false;
  showPassword3: boolean = false;
  submitted: boolean = false;
  designationTypes: any = [];

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    public commonService: CommonService,
    public toastrService: ToastrService,
    public spinner: SpinnerService,
    public apiService: ApiService,
    public authService: AuthenticationService,
    public router: Router
  ) {
    this.authDetail = this.authService.getAuthDetail()['authDetail'];
    if (this.authDetail['profileImageUrl']) {
      this.authDetail['isProfile'] = true;
    }
    if (!this.authDetail['profileImageUrl']) {
      this.authDetail['isProfile'] = false;
      this.authDetail['profile'] =
        this.authDetail['firstName'][0] + '' + this.authDetail['lastName'][0];
    }

    if (this.authDetail['isProfile'] == true) {
      this.url = 'common/manage/user/view/' + this.authDetail.profileImageUrl;
    }
    this.profileForm = this.formBuilder.group({
      birthDate: ['', [Validators.required]],
      address: [''],
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      firstName: ['', [Validators.required, Validators.pattern(NAME_PATTERN)]],
      lastName: ['', [Validators.required, Validators.pattern(NAME_PATTERN)]],
      designation: ['', [Validators.required]],
      profileImageUrl: [''],
      phone: ['', [Validators.required, Validators.pattern(PHONE_PATTERN)]],
      insuranceProvider: [''],
      file: [''],
    });
    this.passwordForm = this.formBuilder.group({
      oldPassword: [
        '',
        [
          Validators.required,
          Validators.pattern(
            PASSWORD_PATTERN),
        ],
      ],
      newPassword: [
        '',
        [
          Validators.required,
          Validators.pattern(PASSWORD_PATTERN),
        ],
      ],
      confirmPassword: [
        '',
        RxwebValidators.compare({ fieldName: 'newPassword' }),
      ],
    });
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this.profileForm.patchValue({
      birthDate: this.authDetail.birthDate,
      address: this.authDetail.address,
      email: this.authDetail.email,
      firstName: this.authDetail.firstName,
      lastName: this.authDetail.lastName,
      phone: this.authDetail.phone,
      pincode: this.authDetail.pincode,
      designation: this.authDetail.designation,
      profileImageUrl: this.authDetail.profileImageUrl,
    });
    this.getDesignationTypes();
  }

  onKey(event: any) {
    this.submitted= true
}

  openModalWithClassEditProfile(template: TemplateRef<any>) {
    this.profileForm.patchValue({
      birthDate: this.authDetail.birthDate,
      address: this.authDetail.address,
      email: this.authDetail.email,
      firstName: this.authDetail.firstName,
      lastName: this.authDetail.lastName,
      phone: this.authDetail.phone,
      pincode: this.authDetail.pincode, 
      designation: this.authDetail.designation,
      profileImageUrl: this.authDetail.profileImageUrl,
    });
    this.modalRef = this.modalService.show(
      template,
      Object.assign(
        {},
        { class: 'gray modal-md modal-dialog-centered edit-profile-new' }
      )
    );
  }

  submitForm() {
    console.log("ProfileForm Value: ",this.profileForm.value);
    let phone = this.profileForm.value.phone;
    let profileImageUrl = this.profileForm.value.profileImageUrl;
    if (this.profileForm.value.profileImageUrl == null) {
      profileImageUrl = ""
    }
    if (this.profileForm.value.phone.length >= 17) {
      phone = phone.slice(0, -1);
    }
    phone = phone.replace('(', '');
    phone = phone.replace(')', '');
    phone = phone.replace(' ', '');
    phone = phone.replace(/[^0-9\.]+/g, '');

    this.profileForm.patchValue({
      phone: phone,
      profileImageUrl: profileImageUrl,
    });
    if (this.url == '') {
      this.profileForm.patchValue({
        profileImageUrl: "",
      });
    }
    if (this.profileForm.valid) {
      let postData = this.profileForm.value;
      delete postData['email'];
      console.log(postData);
      let request = {
        path: 'auth/user/update/' + this.authDetail['id'],
        data: postData,
        isAuth: true,
      };
      this.apiService.post(request).subscribe((response) => {
        if (response['status']['code'] == 'OK') {
          this.toastrService.success(response['status']['description']);
          this.commonService.setNewAuthDetail();
          this.spinner.hide();
          this.modalRef.hide();
          this.submitted = false;
          this.passwordForm.reset();
        } else {
          this.spinner.hide();
          this.modalRef.hide();
          this.toastrService.error(response['status']['description']);
        }
      });
    } else {
      this.spinner.hide();
      this.toastrService.error('All fields are required ');
    }
  }

  uploadPhotoClick() {
    $('#profileupdate').trigger('click');
  }

  logOut() {
    console.log('in logout');
    this.authService.logout();
  }

  updateProfile(event) {
    console.log(event);
    let file = event.target.files;
    let formData = new FormData();
    formData.append('file', file[0]);
    console.log(file[0].size, 1024 * 1024);
    if (file[0].size > 1024 * 1024) {
      this.toastrService.error('Maximum 2MB size allowed');
      return false;
    }
    if (this.allowed_types.indexOf(file[0].type) == -1) {
      this.toastrService.error('Only JPG , JPEG or PNG images are allowed.');
      return false;
    }
    this.spinner.show();
    let data = {
      path: 'common/manage/upload',
      data: formData,
      isAuth: true,
    };
    // this.spinner.show();
    this.apiService.postImage(data).subscribe((response) => {
      if (response['status']['code'] == 'OK') {
        this.profileForm.patchValue({
          profileImageUrl: response['data']['id'],
        });
        this.url =
          'common/manage/user/view/' + this.profileForm.value.profileImageUrl;
      }
      this.spinner.hide();
    });
    console.log(data);
  }

  deleteProfile() {
    Swal.fire({
      title: 'Confirm',
      text: 'Are you sure you want to delete this photo ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        let request = {
          path:
            'common/manage/user/delete/' + this.profileForm.value.profileImageUrl,
          isAuth: true,
        };
        this.apiService.get(request).subscribe((response) => {
          if (response['status']['code'] == 'OK') {
            this.toastrService.success(response['status']['description']);
            this.spinner.hide();
            this.url = ''
            this.profileForm.value.profileImageUrl = null;
            this.authDetail['profile'] = this.authDetail['firstName'][0] + '' + this.authDetail['lastName'][0];
          } else {
            this.toastrService.error(response['status']['description']);
            this.spinner.hide();
          }
          console.log(request);
          console.log(this.profileForm.value);
        });
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your photo is safe :)', 'error');
      }
    });
  }

  openModalWithClassChangePassword(template: TemplateRef<any>) {
    this.modalRef1 = this.modalService.show(template, {
      class: 'gray modal-md modal-dialog-centered change-password-new',
    });
  }

  submitPassword() {
    console.log('clicked');
    this.submitted = true;
    console.log(this.submitted);
    if (this.passwordForm.valid) {
      let regData = {
        path: 'auth/auth/updatePassword',
        data: {
          oldPassword: btoa(this.passwordForm.value.oldPassword),
          newPassword: btoa(this.passwordForm.value.newPassword),
        },
        isAuth: true,
      };
      console.log(regData.data);
      this.apiService.post(regData).subscribe((response) => {
        if (response['status']['code'] == 'OK') {
          this.toastrService.success(response['status']['description']);
          this.modalRef1.hide();
          this.spinner.hide();
          this.submitted = false;
          this.passwordForm.reset();
          console.log(response);
        } else {
          this.toastrService.error(response['status']['description']);
          console.log(response);
        }
      });
    }
  }

  logout() {
    this.authService.logout();
  }

  getDesignationTypes() {
    let request = {
      path: 'common/professionalType',
      isAuth: true,
    };
    this.apiService.get(request).subscribe((response) => {
      if (response['status']['code'] == 'OK') {
        this.designationTypes = response['data'];
      }
    });
  }

  removeValidators() {
    this.modalRef1.hide();
    this.submitted = false;
    this.passwordForm.reset();
  }
  editCancel() {
    this.modalRef.hide();
    for (const key in this.profileForm.controls)
      this.profileForm.get(key).clearValidators();
  }

  openModalWithClassSupport(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(template, {
      class: 'gray modal-md modal-dialog-centered change-password-new',
    });
  }

  openModalWithClassTerms(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(template, {
      class: 'gray modal-md modal-dialog-centered change-password-new',
    });
  }

  openModalWithClassPrivacy(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(template, {
      class: 'gray modal-md modal-dialog-centered change-password-new',
    });
  }

  // openModalWithClass(template: TemplateRef<any>) {
  //   this.modalRef = this.modalService.show(
  //     template,
  //     Object.assign(
  //       {},
  //       { class: 'gray modal-md modal-dialog-centered change-password-new' }
  //     )
  //   );
  // }
}
