import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ToastrModule} from "ngx-toastr";
import {AuthGuard} from '../auth.guard';
import {CommonLayoutComponent} from "../layouts/common-layout/common-layout.component";
import {CommonLayoutModule} from "../layouts/common-layout/common-layout.module";
import { PatientsComponent } from './patients/patients.component';
import { MyTeamComponent } from './my-team/my-team.component';
import {CountdownModule} from 'ngx-countdown';

// import { VideoCallComponent } from './video-call/video-call.component';
// import { ChatComponent } from './chat/chat.component';
import { NewCommonLayoutComponent } from '../layouts/new-common-layout/new-common-layout.component';
import {NewCommonLayoutModule} from "../layouts/new-common-layout/new-common-layout.module";



export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/login/login.module').then(p=>p.LoginModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then(p=>p.LoginModule)
  },
  {
    path: 'register',
    loadChildren:() => import('./auth/register/register.module').then(p=>p.RegisterModule)
  },
  {
    path: 'forgot-password',
    loadChildren:() => import('./auth/forgot-password/forgot-password.module').then(p=>p.ForgotPasswordModule)
  },
  {
    path: 'create-password',
    loadChildren:() => import('./auth/create-password/create-password.module').then(p=>p.CreatePasswordModule)
  },
  {
    path: 'verify/:token',
    loadChildren:() => import('./auth/create-password/create-password.module').then(p=>p.CreatePasswordModule)
  },
  {
    path: 'otp',
    loadChildren:() => import('./auth/otp/otp.module').then(p=>p.OtpModule)
  },
  {
    path: 'activate-your-account',
    loadChildren:() => import('./auth/activate-your-account/activate-your-account.module').then(p=>p.ActivateYourAccountModule)
  },
  {
    path: 'signup',
    loadChildren:() => import('./auth/signup/signup.module').then(p=>p.SignupModule)
  },
  {
    path: 'dashboard',
    loadChildren:() => import('./dashboard/dashboard.module').then(p=>p.DashboardModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  },
  {
    path: 'new-dashboard',
    loadChildren:() => import('../pages/new-designs/new-dashboard/new-dashboard.module').then(p=>p.NewDashboardModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  },
  {
    path: 'new-appointment-requests',
    loadChildren:() => import('../pages/new-appointment-requests/new-appointment-requests.module').then(p=>p.NewAppointmentRequestsModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  },
  {
    path: 'plans-subscriptions',
    loadChildren:() => import('./auth/plans-subscriptions/plans-subscriptions.module').then(p=>p.PlansSubscriptionsModule)
  },
  {
    path: 'patients',
    loadChildren:() => import('../pages/patients/patients.module').then(p=>p.PatientsModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  },
  {
    path: 'patientsNewDesign',
    loadChildren:() => import('../pages/new-designs/patient/patient/patient.module').then(p=>p.PatientModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  },
  {
    path: 'patient-details/:doctorId/:id',
    loadChildren:() => import('../pages/patient-details/patient-details.module').then(p=>p.PatientDetailsModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  },
  {
    path: 'chat',
    loadChildren:() => import('../pages/chat/chat.module').then(p=>p.ChatModule),
    canActivate: [AuthGuard],
    component:CommonLayoutComponent
  },
  {
    path: 'video-call',
    loadChildren:() => import('../pages/video-call/video-call.module').then(p=>p.VideoCallModule),
    canActivate: [AuthGuard],
    // component:CommonLayoutComponent
  },
  {
    path: 'video-call/:id',
    loadChildren:() => import('../pages/video-call/video-call.module').then(p=>p.VideoCallModule),
    canActivate: [AuthGuard],
    // component:CommonLayoutComponent
  },
  {
    path: 'appointments',
    loadChildren:() => import('../pages/appointments/appointments.module').then(p=>p.AppointmentsModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  },
  {
    path: 'appointments/:type',
    loadChildren:() => import('../pages/appointments/appointments.module').then(p=>p.AppointmentsModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  },
  {
    path: 'manage',
    loadChildren:() => import('../pages/manage/manage.module').then(p=>p.ManageModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  },
  {
    path: 'my-team',
    loadChildren:() => import('../pages/my-team/my-team.module').then(p=>p.MyTeamModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  },
  {
    path: 'new-my-team',
    loadChildren:() => import('../pages/new-designs/new-my-team/new-my-team.module').then(p=>p.NewMyTeamModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  },
  {
    path: 'service',
    loadChildren:() => import('../pages/appointment-service/appointment-service.module').then(p=>p.AppointmentServiceModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  },

  {
    path: 'new-service',
    loadChildren:() => import('../pages/new-designs/new-appointment-service/new-appointment-service.module').then(p=>p.NewAppointmentServiceModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  },
  {
    path: 'news',
    loadChildren:() => import('../pages/news/news.module').then(p=>p.NewsModule),
    canActivate: [AuthGuard],
    component:CommonLayoutComponent
  },
  {
    path: 'appointment-new',
    loadChildren:() => import('../pages/new-designs/appointment-new/appointment-new.module').then(p=>p.AppointmentNewModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  }, {
    path: 'appointment-new/:type',
    loadChildren:() => import('../pages/new-designs/appointment-new/appointment-new.module').then(p=>p.AppointmentNewModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  }, {
    path: 'appointment-new/:month/:year',
    loadChildren:() => import('../pages/new-designs/appointment-new/appointment-new.module').then(p=>p.AppointmentNewModule),
    canActivate: [AuthGuard],
    component:NewCommonLayoutComponent
  }
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CommonLayoutModule,
    NewCommonLayoutModule,
    CountdownModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    RouterModule.forRoot(routes,{}),
  ],
  exports:[RouterModule]
})
export class PagesModule { }
