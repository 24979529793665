import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PhoneMaskDirective} from "./phone-mask.directive";
import { AutocompleteDirective } from './autocomplete.directive';



@NgModule({
  declarations: [PhoneMaskDirective, AutocompleteDirective],
  imports: [
    CommonModule
  ],
  exports: [
    PhoneMaskDirective,
    AutocompleteDirective
  ]
})
export class DirectivesModule { }
