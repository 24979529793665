import {Injectable} from '@angular/core';
import {configuration} from '../configration';
import {Router} from "@angular/router";
import {E2EService} from "./e2e.service";
const AUTH = configuration.AUTH_KEY;


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService{
  public data:any=[];


  constructor(public router: Router) {
    if (localStorage.getItem(AUTH) != null){
      this.data = (JSON.parse(localStorage.getItem(AUTH)) as any);
    }
  }


  getToken(){
    return this.data.token;
  }
  getAuthDetail(){
    return this.data;
  }

  setAuth(data){
    console.log(data);
    if (data['authDetail'] && data['authDetail']['profileImageUrl']) {
      data['authDetail']['isProfile'] = true;
    }
    if (data['authDetail'] && !data['authDetail']['profileImageUrl']) {
      data['authDetail']['isProfile'] = false;
    }
    this.data = data;
    localStorage.setItem(AUTH, JSON.stringify(data));
  }
  setCurrentAuth(){
    localStorage.setItem(AUTH, JSON.stringify(this.data));
  }
  logout()
  {
    localStorage.removeItem('auth');
    localStorage.removeItem('doctorDetails');
    location.reload();

  }

  isAuthenticated(){
    return this.data.isLoggedIn;
  }
  setNewAuthDetail(data)
  {
    this.data['authDetail']=data['0'];
    if (this.data['authDetail']['profileImageUrl']) {
      this.data['authDetail']['isProfile'] = true;
    }
    if (!this.data['authDetail']['profileImageUrl']) {
      this.data['authDetail']['isProfile'] = false;
      if (this.data['authDetail']['firstName']) {
        this.data['authDetail']['profile'] = this.data['authDetail']['firstName'][0];
      }
      if (this.data['authDetail']['lastName']) {
        this.data['authDetail']['profile'] += this.data['authDetail']['lastName'][0];
      }
    }
    localStorage.setItem(AUTH, JSON.stringify(this.data));
  // location.reload();

  }
}



export class AuthData{
  isLoggedIn:boolean
  token: string;
  authDetail:any
}
