import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { configuration } from '../configration';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { catchError, map, retry } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from './spinner.service';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    public http: HttpClient,
    public spinner: SpinnerService,
    public router: Router,
    private authService: AuthenticationService,
    public toastrService: ToastrService
  ) {}

  get(request: Request) {
    return this.http
      .get(configuration.BASE_URL + request.path, {
        headers: this.getHeader(request),
      })
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  getAudio(request: Request) {
    const token = this.authService.getToken();
    const headers = new HttpHeaders({ TOKEN: ` ${token}` });
    return this.http
      .get(configuration.BASE_URL + request['path'], {
        headers,
        responseType: 'blob',
      })
      .toPromise()
      .then((imageBlob) => {
        const reader = new FileReader();
        // return imageBlob
        return new Promise((resolve) => {
          console.log(imageBlob);
          reader.onloadend = () => resolve(reader.result as string);
          reader.readAsDataURL(imageBlob);
        });
      })
      .catch((error) => {
        console.log(error);
        catchError(this.handleError.bind(this));
      });
  }

  post(request): Observable<any[]> {
    return this.http
      .post<any[]>(configuration.BASE_URL + request['path'], request['data'], {
        headers: this.getHeader(request),
      })
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  postImage(request: Request) {
    let headers = new HttpHeaders();
    const token = this.authService.getToken();
    if (request.isAuth) {
      headers = new HttpHeaders({ TOKEN: token });
    }
    console.log(token);
    return this.http
      .post(configuration.BASE_URL + request.path, request.data, {
        headers: headers,
      })
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  getUrl(request: Request) {
    return this.http.get(request.path, {});
  }

  delete(request: Request) {
    return this.http.delete(configuration.BASE_URL + request.path, {
      headers: this.getHeader(request),
    });
  }

  postWithoutToken(request): Observable<any[]> {
    return this.http
      .post<any[]>(configuration.BASE_URL + request['path'], request['data'], {
        headers: this.getHeaderWithoutHeader(request),
      })
      .pipe(
        map((result) => {
          if (result['token']) {
            let data = this.authService.getAuthDetail();
            data = {
              token: result['token'],
              isLoggedIn: true,
              ...data,
            };
            this.authService.setAuth(data);
          }
          return result;
        }),
        catchError(this.handleError.bind(this))
      );
  }

  getHeaderWithoutHeader(request: Request) {
    let token = JSON.parse(localStorage.getItem('token'));
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      // 'Access-Control-Allow-Origin': '*',
    });
    return header;
  }

  handleError(error) {
    this.spinner.hide();
    if (error['error'] != undefined) {
      if (error['error']['status']['code']) {
        this.spinner.hide();
        this.toastrService.error(error['error']['status']['code']);
      }
    }
    this.spinner.hide();
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // this.toastrService.error(error['error']['error']);
      // client-side error
      errorMessage = error; //`Error: ${error.error.message}`;
    } else {
      // this.toastrService.error(error['error']['error']);
      // server-side error
      errorMessage = error; //`Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  login(request): Observable<any[]> {
    return this.http.post<any[]>(
      configuration.BASE_URL + request.path,
      request.data,
      {}
    );
  }

  // post(request:Request){
  //   return this.http.post(configuration.BASE_URL+request.path,request.data,{
  //     headers:this.getHeader(request),
  //     observe:"response",
  //   });
  // }

  put(request: Request) {
    return this.http.put(configuration.BASE_URL + request.path, request.data, {
      headers: this.getHeader(request),
    });
  }

  // postImage(request:Request){
  //   let headers = new HttpHeaders();
  //   if(request.isAuth){
  //     headers = new HttpHeaders({"Authorization":"Bearer "+this.auth.details.accessToken});
  //   }
  //   return this.http.post(configuration.BASE_URL+request.path,request.data,{
  //     headers: headers
  //   });
  // }

  private getHeader(request: Request) {
    const zone = moment.tz.guess(); // "America/Los_Angeles"
    const abbr = moment.tz(zone).format('z');
    const token = this.authService.getToken();
    let header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      timezone: abbr,
    });

    if (this.authService.isAuthenticated()) {
      header = header.append('TOKEN', [token]);
    }
    if (request.doctorId) {
      header = header.append('id', request.doctorId);
    }
    return header;
  }

  updatePassword(request: Request) {
    return this.http
      .post(configuration.BASE_URL + request.path, request.data, {
        headers: this.getHeader(request),
      })
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }
}

export interface Request {
  path: string;
  data?: any;
  isAuth?: boolean;
  doctorId?: any;
}
