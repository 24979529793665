import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewCommonLayoutComponent } from './new-common-layout.component';
import {RouterModule} from "@angular/router";
import {MenuModule} from "../menu/menu.module";
import {PipesModule} from "../../pipes/pipes.module";
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { MatInputModule } from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import {MatSelectModule} from '@angular/material/select';



@NgModule({
  declarations: [NewCommonLayoutComponent],
    imports: [
        CommonModule,
        RouterModule,
        MenuModule,
        PipesModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatNativeDateModule,
        MatDatepickerModule,
        ModalModule.forRoot(),
        MatSelectModule
    ],
  exports: [
    NewCommonLayoutComponent
  ]
})
export class NewCommonLayoutModule { }
