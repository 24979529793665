import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {ApiService, AuthenticationService} from "./service";
import {ComponentsModule} from "./components/components.module";
import {DirectivesModule} from "./directives/directives.module";
import {PagesModule} from "./pages/pages.module";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {NgxSpinnerModule} from "ngx-spinner";
import {SpinnerService} from "./service/spinner.service";
import {E2EService} from "./service/e2e.service";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {configuration} from "./configration";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NewCommonLayoutComponent } from './layouts/new-common-layout/new-common-layout.component';
import {PipesModule} from "./pipes/pipes.module";
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { MatInputModule } from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatTabsModule} from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import {CountdownModule} from 'ngx-countdown';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  //return new TranslateHttpLoader(http,'assets/i18n/','.json');
  return new TranslateHttpLoader(http, configuration.BASE_URL +'common/translation/web/','');


}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    ComponentsModule,
    HttpClientModule,
    DirectivesModule,
    PagesModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatSelectModule,
    CountdownModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    PipesModule,
  ],
  providers: [
    ApiService, AuthenticationService,SpinnerService,E2EService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
