import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonLayoutComponent } from './common-layout.component';
import {RouterModule} from "@angular/router";
import {MenuModule} from "../menu/menu.module";
import {PipesModule} from "../../pipes/pipes.module";



@NgModule({
  declarations: [CommonLayoutComponent],
    imports: [
        CommonModule,
        RouterModule,
        MenuModule,
        PipesModule
    ],
  exports: [
    CommonLayoutComponent
  ]
})
export class CommonLayoutModule { }
