import { Pipe, PipeTransform } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthenticationService} from "../service";
import {SpinnerService} from "../service/spinner.service";
import {configuration} from "../configration";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";

@Pipe({
  name: 'profileImg'
})
export class ProfileImgPipe implements PipeTransform {

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService, // our service that provides us with the authorization token
    public spinner: SpinnerService,
  ) {}

  transform(src: string): Observable<string> {
    const token = this.auth.getToken();

    const headers = new HttpHeaders({'TOKEN': ` ${token}`});
    // const image = new BehaviorSubject();
    const image = Observable.create((observer)=>{
      observer.next('assets/images/no-img.png');
      this.http.get(configuration.BASE_URL +src, {headers, responseType: 'blob'}).toPromise().then((imageBlob)=>{
        const reader = new FileReader();
        this.spinner.hide();
        console.log(imageBlob)
        reader.onloadend = () => observer.next(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });

    })

    return image.pipe();

  }
}
