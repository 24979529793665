import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {decryptWithPrivateKey, encryptWithPublicKey, generatePublicAndPrivateKey} from "../helper/encryption";
declare var CryptoJS:any;

@Injectable()
export class E2EService{

  // Loaded Private Key From Server
  public privateKey:string;


  constructor(private apiService:ApiService) {
    // (window as any).e2e = this; FOR Debugging
  }


  // rsaKey(){
    // Generating Private And Public Key ...
    // return generatePublicAndPrivateKey();
  // }



  loadPrivateKey(){
    let {privateKey,publicKey} = generatePublicAndPrivateKey();
    let request = {
      path : "auth/user/getPrivateKey",
      data : {
        publicKey
      }
    }

    this.apiService.post(request).subscribe((data)=>{
      // console.error("Response from the server for private Key",data);
      if(data['data']){
        this.privateKey = decryptWithPrivateKey(privateKey,data["data"]);
        // console.error("Private Key From Server",this.privateKey);
      }
    });

  }


  // Chat Room Generate Private Key
  generateSharedKey(){
    let salt = CryptoJS.lib.WordArray.random(128/8);
    let key512Bits = CryptoJS.PBKDF2(makeid(10), salt, { keySize: 256/32 });
    return key512Bits.toString();
  }


  // TEST Method
  encryptWithPublicKey(publicKey,text){
    return encryptWithPublicKey(publicKey,text);
  }



  unloadPrivateKey(){
    this.privateKey = null;
  }

  // Message Encoding And Decoding

  decodeChatRoomMessage(message,roomSharedKey){
    let sharedKey = decryptWithPrivateKey(this.privateKey,roomSharedKey);
    return CryptoJS.AES.decrypt(message, sharedKey).toString(CryptoJS.enc.Utf8);
  }

  encodeChatRoomMessage(message,roomSharedKey){
    let sharedKey = decryptWithPrivateKey(this.privateKey,roomSharedKey);
    return  CryptoJS.AES.encrypt(message, sharedKey).toString();
  }
  getSharedKey(publicKey,roomSharedKey) {
    let sharedKey = decryptWithPrivateKey(this.privateKey,roomSharedKey);
    return this.encryptWithPublicKey(publicKey,sharedKey);
  }
}



function makeid(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
