import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageComponentComponent } from './image-component/image-component.component';
import {PipesModule} from "../pipes/pipes.module";
import { AppointmentCardComponent } from './appointment-card/appointment-card.component';
import { PatientCardComponent } from './patient-card/patient-card.component';
import {MatCardModule} from "@angular/material/card";
import {MatTabsModule} from "@angular/material/tabs";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {RouterModule} from "@angular/router";
import {ModalModule} from "ngx-bootstrap/modal";
import { AppointmentListCardComponent } from './appointment-list-card/appointment-list-card.component';
import {AffinipayComponent} from "./affinipay/affinipay.component";
import {FileUploaderVerticalComponent} from "./file-uploader-vertical/file-uploader-vertical.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import { MyTeamCardComponent } from './my-team-card/my-team-card.component';
import {MatIconModule} from "@angular/material/icon";
import {ReactiveFormsModule} from "@angular/forms";
import {MatSelectModule} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {DirectivesModule} from "../directives/directives.module";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { FormsModule } from '@angular/forms';
import {Ng5SliderModule} from 'ng5-slider';
import { HeartLogDetailComponent } from './heart-log-detail/heart-log-detail.component';
import { PatientDetailNewComponent } from './patient-detail-new/patient-detail-new.component';
import { AppointmentDetailNewComponent } from './appointment-detail-new/appointment-detail-new.component';
import {MatExpansionModule} from "@angular/material/expansion";

@NgModule({
  declarations: [
                ImageComponentComponent,
                AppointmentCardComponent,
                PatientCardComponent,
                AppointmentListCardComponent,
                AffinipayComponent,
                FileUploaderVerticalComponent,
                MyTeamCardComponent,
                HeartLogDetailComponent,
                PatientDetailNewComponent,
                AppointmentDetailNewComponent
  ],
    imports: [
        CommonModule,
        PipesModule,
        MatCardModule,
        MatTabsModule,
        BsDropdownModule,
        CommonModule,
        MatCardModule,
        ModalModule,
        ModalModule.forRoot(),
        MatTabsModule,
        BsDropdownModule.forRoot(),
        MatInputModule,
        FormsModule,
        MatFormFieldModule,
        RouterModule,
        InfiniteScrollModule,
        MatIconModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatDatepickerModule,
        DirectivesModule,
        MatCheckboxModule,
        Ng5SliderModule,
        MatExpansionModule,
    ],
    exports: [
        ImageComponentComponent,
        AppointmentCardComponent,
        PatientCardComponent,
        AppointmentListCardComponent,
        AffinipayComponent,
        FileUploaderVerticalComponent,
        MyTeamCardComponent,
        HeartLogDetailComponent,
        PatientDetailNewComponent,
        AppointmentDetailNewComponent
    ],

})
export class ComponentsModule { }
