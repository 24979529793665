import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthImagePipe } from './auth-img/auth-image.pipe';
import {niceDateFormatPipe} from "./niceDateFormat.pipe";
import { SharedImgPipe } from './shared-img/shared-img.pipe';
import { ProfileImgPipe } from './profile-img.pipe';
import {DecryptionPipe} from "./decryption.pipe";
import { ReplacePipe } from './replace.pipe';



@NgModule({
  declarations: [AuthImagePipe,niceDateFormatPipe, SharedImgPipe, ProfileImgPipe,DecryptionPipe, ReplacePipe],
  exports: [
    AuthImagePipe,
    niceDateFormatPipe,
    SharedImgPipe,
    ProfileImgPipe,
    DecryptionPipe,
    ReplacePipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule {}
