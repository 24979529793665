<div class="menu-list">
  <ul>
    <li [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"  [routerLink]="'/dashboard'">
      <a href="javascript:void(0)">
        <i aria-hidden="true" class="icon icon-home"></i>
        <!-- <img src="assets/icon/home.svg"> -->
        <span>Home</span>
      </a>
    </li>
    <li [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" [routerLink]="'/new-appointment-requests'">
      <a  href="javascript:void(0)">
        <img src="/assets/icon/request.svg" class="team0">
        <img src="/assets/icon/request-w.svg" class="team1">
        <!-- <i aria-hidden="true" class="icon icon-blood"></i> -->
        <span>Requests</span>
      </a>
    </li>
    <li [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"  [routerLink]="'/appointments'" class="appontment">
      <a href="javascript:void(0)">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="23.674" height="28.934" viewBox="0 0 23.674 28.934">
          <defs>
            <clipPath id="clip-path">
              <path id="Combined_Shape" data-name="Combined Shape" d="M3.945,28.934A3.949,3.949,0,0,1,0,24.989V3.945A3.949,3.949,0,0,1,3.945,0H14.467a1.309,1.309,0,0,1,.754.238l0,0,.011.008A1.3,1.3,0,0,1,15.4.385l7.892,7.892a1.3,1.3,0,0,1,.136.16l.009.012,0,0a1.309,1.309,0,0,1,.238.723q0,.016,0,.033V24.989a3.949,3.949,0,0,1-3.945,3.945ZM2.63,3.945V24.989A1.316,1.316,0,0,0,3.945,26.3H19.728a1.316,1.316,0,0,0,1.315-1.315V10.522H14.467a1.316,1.316,0,0,1-1.315-1.315V2.63H3.945A1.316,1.316,0,0,0,2.63,3.945ZM15.782,7.892h3.4l-3.4-3.4ZM6.577,22.359a1.315,1.315,0,1,1,0-2.63H17.1a1.315,1.315,0,1,1,0,2.63Zm0-5.261a1.315,1.315,0,1,1,0-2.63H17.1a1.315,1.315,0,1,1,0,2.63Zm0-5.26a1.315,1.315,0,1,1,0-2.63h2.63a1.315,1.315,0,0,1,0,2.63Z" fill="#bab7b7"/>
            </clipPath>
          </defs>
          <g id="Group_13010" data-name="Group 13010" transform="translate(-3 -1)">
            <path id="Combined_Shape-2" data-name="Combined Shape" d="M3.945,28.934A3.949,3.949,0,0,1,0,24.989V3.945A3.949,3.949,0,0,1,3.945,0H14.467a1.309,1.309,0,0,1,.754.238l0,0,.011.008A1.3,1.3,0,0,1,15.4.385l7.892,7.892a1.3,1.3,0,0,1,.136.16l.009.012,0,0a1.309,1.309,0,0,1,.238.723q0,.016,0,.033V24.989a3.949,3.949,0,0,1-3.945,3.945ZM2.63,3.945V24.989A1.316,1.316,0,0,0,3.945,26.3H19.728a1.316,1.316,0,0,0,1.315-1.315V10.522H14.467a1.316,1.316,0,0,1-1.315-1.315V2.63H3.945A1.316,1.316,0,0,0,2.63,3.945ZM15.782,7.892h3.4l-3.4-3.4ZM6.577,22.359a1.315,1.315,0,1,1,0-2.63H17.1a1.315,1.315,0,1,1,0,2.63Zm0-5.261a1.315,1.315,0,1,1,0-2.63H17.1a1.315,1.315,0,1,1,0,2.63Zm0-5.26a1.315,1.315,0,1,1,0-2.63h2.63a1.315,1.315,0,0,1,0,2.63Z" transform="translate(3 1)" fill="#bab7b7"/>
            <g id="Mask_Group_37" data-name="Mask Group 37" transform="translate(3 1)" clip-path="url(#clip-path)">
              <g id="_Color" data-name="🎨Color" transform="translate(-3.946 -1.315)">
                <rect id="Rectangle" width="31.565" height="31.565" fill="#bab7b7"/>
              </g>
            </g>
          </g>
        </svg>
        <span>Appointments</span>
      </a>
    </li>
    <li [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" [routerLink]="'/patients'">
      <a href="javascript:void(0)" >
        <i aria-hidden="true" class="icon icon-patients"></i>
        <span>PATIENTS</span>
      </a>
    </li>
    <li [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" [routerLink]="'/my-team'">
      <a href="javascript:void(0)" >
        <img src="/assets/icon/team.svg" class="team0">
        <img src="/assets/icon/team-white.svg" class="team1">
        <span>My Team</span>
      </a>
    </li>
    <li [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" [routerLink]="'/service'">
      <a href="javascript:void(0)" >
        <!-- <i aria-hidden="true" class="icon icon-manage"></i> -->
        <img src="/assets/icon/manage-menu.svg" class="team0">
        <img src="/assets/icon/manage-menu-w.svg" class="team1">
        <span>Manage</span>
      </a>
    </li>
  </ul>
</div>
