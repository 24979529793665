import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AuthenticationService} from "../../service";
@Component({
  selector: 'app-common-layout',
  templateUrl: './common-layout.component.html',
  styleUrls: ['./common-layout.component.scss']
})
export class CommonLayoutComponent implements OnInit {
  authDetail:any=[];
  showMenu:boolean=true;
  currentYear:any;
  constructor(public authService :AuthenticationService,) {
    this.authDetail = this.authService.data['authDetail'];
    if(this.authDetail['profileImageUrl'])
    {
      this.authDetail['isProfile'] = true;
    }
    if(!this.authDetail['profileImageUrl'])
    {
      this.authDetail['isProfile'] = false;
      this.authDetail['profile'] = this.authDetail['firstName'][0] + "" +  this.authDetail['lastName'][0]
    }
  }

  ngOnInit(): void {
    this.currentYear=new Date().getFullYear();
  }

}
