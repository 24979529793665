import {ExtraOptions} from '@angular/router';

export class Configuration {
  BASE_URL = '';
  AUTH_KEY: string = "auth";
  CHAT_URL =   '';
  VIDEO_CALL_URL = '';
  API_KEY='';
  SESSION_ID='';
  TOKEN='';
  constructor(public state: projectState) {
    if (this.state == 'production') {
      this.CHAT_URL = 'wss://preprod-chat.telecardiocare.com';
      this.VIDEO_CALL_URL = 'wss://preprod-telehealth.telecardiocare.com';
      this.BASE_URL = 'https://preprod-apis.telecardiocare.com/';
    } else if (this.state == "staging") {
      this.CHAT_URL = 'wss://dev-chat.telecardiocare.com';
      this.VIDEO_CALL_URL = 'wss://dev-telehealth.telecardiocare.com';
      this.BASE_URL = 'https://dev-api.telecardiocare.com/';
      //this.BASE_URL ='https://preprod-api.techrover.us/'
    } else if (this.state == "local") {
      this.CHAT_URL = 'ws://localhost:5106/';
      this.VIDEO_CALL_URL = 'ws://localhost:5104/';
      this.BASE_URL = 'http://localhost:5100/';
    } else {
      this.BASE_URL = "";
    }
  }

  convertTimeToArray(time12h) {
    let [hours, minutes] = time12h.split(':');
    hours = parseInt(hours);
    minutes = parseInt(minutes);
    let array = {}
    array['hours'] = hours;
    array['minute'] = minutes
    console.log(array)
    return array;
  }

  GetTime(hours, minutes) {
    if (minutes < 10) {
      minutes = "0" + minutes
    }
    if (hours < 10) {
      hours = "0" + hours
    }
    let time = hours + ":" + minutes;
    return time;
  }

  formatTime(hours, minutes) {
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  format_date(d) {
    var monthNames = [
      "Jan", "Feb", "Mar",
      "Apr", "May", "Jun", "Jul",
      "Aug", "Sep", "Oct",
      "Nov", "Dec"
    ];
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let dateTime = d.toString().replace(/([+\-]\d\d)(\d\d)$/, "$1:$2");
    var dateObj = new Date(dateTime);
    var day = dateObj.getDate();
    var monthIndex = dateObj.getMonth();
    var year = dateObj.getFullYear();
    var dayName = days[dateObj.getDay()];
    var hours = dateObj.getHours();
    var minutes: any = dateObj.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    let a;
    a = dayName + ' ' + monthNames[monthIndex] + ' ' + day + ', ' + year + ' ' + strTime;

    return a;
  }
  formatDateTime(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = '' + d.getHours(),
      minutes = '' + d.getMinutes();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (hours.length < 2)
      hours = '0' + hours;
    if (minutes.length < 2)
      minutes = '0' + minutes;
    return [year, month, day].join('-') + 'T' + [hours, minutes].join(':');
  }
  ageFromDate(dob){
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
}

export const routeConfig: ExtraOptions = {}


type projectState = 'staging' | 'production' | "local";

export const configuration = new Configuration('production');


