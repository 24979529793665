<section class="new-layout">
  <header>
    <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" href="/new-dashboard">
        <img src="../../../assets/icon/new-layout-logo.png" alt="logo" class="logo">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <div class="menu-items">
          <ul class="navbar-nav ">
            <li class="nav-item" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
              [routerLink]="'/new-dashboard'">
              <a class="nav-link" id="icon" href="/new-dashboard">
                <div class="nav-icon">
                  <img src="../../../assets/icon/home-color.svg" alt="">
                </div>
                <div class="nav-icon-gray">
                  <img src="../../../assets/icon/home-gray.svg" alt="">
                </div>
                Home
              </a>
            </li>
            <!-- <li class="nav-item" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
              [routerLink]="'/new-appointment-requests'">
              <a class="nav-link" id="icon" href="#">
                <div class="nav-icon">
                  <img src="../../../assets/icon/home-color.svg" alt="">
                </div>
                <div class="nav-icon-gray">
                  <img src="../../../assets/icon/home-gray.svg" alt="">
                </div>
                Requests
              </a>
            </li>-->
            <li class="nav-item" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
              [routerLink]="'/appointment-new'">
              <a class="nav-link" id="icon" href="/appointment-new">
                <div class="nav-icon">
                  <img src="../../../assets/icon/appointment-color.svg" alt="">
                </div>
                <div class="nav-icon-gray">
                  <img src="../../../assets/icon/appointment-gray.svg" alt="">
                </div>
                Appointments
              </a>
            </li>
            <li class="nav-item" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
              [routerLink]="'/patientsNewDesign'">
              <a class="nav-link" id="icon" href="/patientsNewDesign">
                <div class="nav-icon">
                  <img src="../../../assets/icon/patient.svg" alt="">
                </div>
                <div class="nav-icon-gray">
                  <img src="../../../assets/icon/patient-gray.svg" alt="">
                </div>
                Patients
              </a>
            </li>
            <li class="nav-item" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
              [routerLink]="'/new-my-team'">
              <a class="nav-link" id="icon" href="/new-my-team">
                <!-- <img src="my-team.png" alt="my-team-icon"> -->
                <div class="nav-icon">
                  <img src="../../../assets/icon/my-team.svg" alt="">
                </div>
                <div class="nav-icon-gray">
                  <img src="../../../assets/icon/my-team-gray.svg" alt="">
                </div>
                My Team
              </a>
            </li>
          </ul>
        </div>
        <div class="social-items">
          <ul class="navbar-nav">
            <!-- <li class="nav-item">
                <a href="#" class="nav-link">
                  <img src="../../../assets/icon/social1.png">
                </a>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <img src="../../../assets/icon/social2.png">
                </a>
              </li> -->
            <li class="nav-item" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
              [routerLink]="'/new-service'">
              <a href="/new-service" class="nav-link" style="margin-top: 11px;">
                <img src="../../../assets/icon/social3.png">
              </a>
            </li>
            <!--<li class="nav-item" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
            [routerLink]="'/patients'">
            <a href="#" class="nav-link" style="margin-top: 11px;">
              <img src="../../../assets/icon/social3.png">
            </a>
          </li>-->
            <li class="nav-item">
              <a class="nav-link pr-0">
                <div class="round-part menu-profile">
                  <button mat-button [matMenuTriggerFor]="beforeMenu">
                    <img *ngIf="this.authService.getAuthDetail()['authDetail']?.isProfile"
                      [src]="'common/manage/user/view/'+this.authService.getAuthDetail()['authDetail']['profileImageUrl'] | profileImg | async">
                    <span
                      *ngIf="!this.authService.getAuthDetail()['authDetail']?.isProfile">{{this.authService.getAuthDetail()['authDetail']['profile']}}</span>
                  </button>
                  <mat-menu #beforeMenu="matMenu" xPosition="before">
                    <button mat-menu-item (click)="openModalWithClassEditProfile(templateeditprofile)"><img
                        src="../../../assets/images/edit-profile-icon.png">Edit Profile</button>

                    <button mat-menu-item (click)="openModalWithClassChangePassword(template)"><img class="lock-img"
                        src="../../../assets/icon/pass-lock.svg">Change Password</button>

                    <button mat-menu-item (click)="logout()"><img
                        src="../../../assets/images/logout-icon.png">Logout</button>
                  </mat-menu>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</section>
<router-outlet></router-outlet>
<footer>
  <div class="box">
    <div class="row">
      <div class="col-md-7">
        <div class="copyright">
          <p>@{{currentYear}} Tele Cardio Care. <span>All right reserved</span></p>
        </div>
      </div>
      <div class="col-md-5">
        <div class="foot-menu">
          <ul>
            <li (click)="openModalWithClassSupport(templatesupport)">support</li>
            <li (click)="openModalWithClassTerms(templateterms)">terms</li>
            <li (click)="openModalWithClassPrivacy(templateprivacy)">privacy</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- modal for edit profile -->
<ng-template #templateeditprofile>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Edit Profile</h4>
    <button (click)="editCancel()" aria-label="Close" class="close-btn" type="reset">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="profileForm" autocomplete="off">
      <div class="header">
        <div class="row">
          <div class="col-12">
            <div class="upload-img">
              <!-- <img src="../../../assets/images/edit-profile-image.png" *ngIf = "this.url == null"> -->
              <img *ngIf="this.url !=''" [src]="this.url | profileImg | async" [alt]="">
              <span *ngIf="this.url ==''">{{ this.authDetail['profile']}}</span>

              <i class="text-change-photo" (click)="uploadPhotoClick();">
                <img src="../../../assets/images/change-photo-icon.png">Change Photo
              </i>
              <p class="remove-img" *ngIf="this.url !=''" (click)="deleteProfile()">
                REMOVE PHOTO
              </p>
            </div>
            <input type="file" formControlName="file" accept="image/png, image/jpeg" id="profileupdate" #profilePic
              (change)="updateProfile($event)" style="display: none;" />
          </div>
        </div>
      </div>
      <div class="edit-profile-row">
        <div class="row">
          <div class="col-md-6">
            <div class="edit-profile-fields">
              <mat-form-field appearance="outline">
                <mat-label>First Name *</mat-label>
                <input matInput formControlName="firstName" placeholder="Enter First Name">
                <mat-error>First Name is
                  <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>

          </div>

          <div class="col-md-6">
            <div class="edit-profile-fields">
              <mat-form-field appearance="outline">
                <mat-label>Last Name *</mat-label>
                <input matInput formControlName="lastName" placeholder="Enter Last Name">
                <mat-error>Last Name is
                  <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-md-6">
            <div class="edit-profile-fields">
              <mat-form-field appearance="outline">
                <mat-label>Email *</mat-label>
                <input matInput formControlName="email" placeholder="Enter Email" readonly>
                <mat-error>Email is
                  <strong>not valid</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-md-6">
            <div class="edit-profile-fields">
              <mat-form-field appearance="outline">
                <mat-label>Designation *</mat-label>
                <input matInput formControlName="designation" placeholder="Enter designation">
                <mat-error>Designation is
                  <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-md-6">
            <div class="edit-profile-fields">
              <div class="form-group">
                <mat-form-field appearance="outline">
                  <mat-label>Date of Birth *</mat-label>
                  <input [matDatepicker]="picker" matInput formControlName="birthDate">
                  <mat-datepicker-toggle [for]="picker" matSuffix>
                    <mat-icon class="date" matSuffix>
                    </mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error>Date of Birth is
                    <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="edit-profile-fields">
              <mat-form-field appearance="outline">
                <mat-label>Address</mat-label>
                <input matInput formControlName="address" placeholder="Enter Address">
              </mat-form-field>
            </div>
          </div>

          <!-- <div class="col-md-12">
            <div class="card-change-password" (click)="openModalWithClassChangePassword(template)">
              <h5>Change Password
                <img src="assets/icon/card-arrow.svg">
              </h5>
            </div>
          </div> -->
        </div>
      </div>
    </form>
  </div>


  <div class="modal-footer">
    <div class="row">
      <div class="col-md-12">
        <div class="footer-btn">
          <button (click)="modalRef.hide()" aria-label="Close" class="cancel-btn"><img
              src="assets/icon/cancel-icon.svg"> Cancel
          </button>
          <!-- <button class="next-btn"> Next <img src="assets/icon/next-btn-icon.svg"></button> -->
          <button class="save-btn" type="submit" (click)="submitForm()"><img src="assets/icon/save-icon.svg"> Save
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title">Change Password </h4>
    <button (click)="removeValidators()" aria-label="Close" class="close-btn" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="change-password-modal-data">
      <form [formGroup]="passwordForm" autocomplete="off">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <mat-form-field appearance="outline" *ngIf="!showPassword1">
                <mat-label>Old Password</mat-label>
                <input matInput formControlName="oldPassword" type="password" placeholder="Enter Old Password">
                <mat-icon (click)="showPassword1 = !showPassword1" matSuffix>
                  <img class="eye-icon" src="assets/icon/view.svg">
                </mat-icon>
              </mat-form-field>
              <mat-form-field appearance="outline" *ngIf="showPassword1">
                <mat-label>Old Password</mat-label>
                <input matInput formControlName="oldPassword" type="text" placeholder="Enter Old Password">
                <mat-icon (click)="showPassword1 = !showPassword1" matSuffix>
                  <img class="eye-icon" src="assets/icon/view.svg">
                </mat-icon>
              </mat-form-field>
              <div *ngIf="submitted && passwordForm.controls.oldPassword.errors" class="error-msg">
                <span>Old Password is required.</span>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <mat-form-field appearance="outline" *ngIf="!showPassword2">
                <mat-label>New Password</mat-label>
                <input matInput formControlName="newPassword" type="password" placeholder="Enter New Password">
                <mat-icon (click)="showPassword2 = !showPassword2" matSuffix>
                  <img class="eye-icon" src="assets/icon/view.svg">
                </mat-icon>
              </mat-form-field>
              <mat-form-field appearance="outline" *ngIf="showPassword2">
                <mat-label>New Password</mat-label>
                <input matInput formControlName="newPassword" type="text" placeholder="Enter New Password">
                <mat-icon (click)="showPassword2 = !showPassword2" matSuffix>
                  <img class="eye-icon" src="assets/icon/view.svg">
                </mat-icon>
              </mat-form-field>
              <div *ngIf="passwordForm.controls.newPassword.errors" style="font-size: 12px; color: #6e6e6e; font-weight: bold;">
                <img src="../../../assets/icon/i-icon.svg" style="margin-right: 2px;">
                <span>Password must contain more than 8 characters, 1 upper case letter, 1 Number, and 1 special
                  character</span>
              </div>
              <div *ngIf="submitted && passwordForm.controls.newPassword.errors"  class="error-msg">
                <span>New Password is Required</span>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <mat-form-field appearance="outline" *ngIf="!showPassword3">
                <mat-label>Confirm Password</mat-label>
                <input matInput formControlName="confirmPassword" type="password"
                  placeholder="Enter New Password Again">
                <mat-icon (click)="showPassword3 = !showPassword3" matSuffix>
                  <img class="eye-icon" src="assets/icon/view.svg">
                </mat-icon>
              </mat-form-field>
              <mat-form-field appearance="outline" *ngIf="showPassword3">
                <mat-label>Confirm Password</mat-label>
                <input matInput formControlName="confirmPassword" type="text" placeholder="Enter New Password Again">
                <mat-icon (click)="showPassword3 = !showPassword3" matSuffix>
                  <img class="eye-icon" src="assets/icon/view.svg">
                </mat-icon>
              </mat-form-field>
              <div *ngIf="passwordForm.controls.confirmPassword.errors" class="error-msg">
                <span>Password doesn't match</span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-md-12">
        <div class="footer-btn">
          <button class="save-btn" type="submit" (click)="submitPassword()">
            <img src="../../../assets/icon/right.svg">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #templatesupport>
  <div class="modal-header">
    <h4 class="modal-title">Support</h4>
    <button (click)="modalRef2.hide()" aria-label="Close" class="close-btn" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
      industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
      and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
      into electronic typesetting, remaining essentially unchanged.
    </p>

    <p>
      It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
      and more recently with desktop publishing software like Aldus PageMaker including versions
      of Lorem Ipsum.
    </p>

    <p>
      Why do we use it?
      It is a long established fact that a reader will be distracted by the readable content of a page when looking
      at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
      as opposed to using 'Content here, content here', making it look like readable English.
    </p>

    <p>
      Many desktop publishing packages and web page editors now use Lorem
      Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in
      their infancy.
    </p>
  </div>
</ng-template>

<ng-template #templateterms>
  <div class="modal-header">
    <h4 class="modal-title">Terms</h4>
    <button (click)="modalRef2.hide()" aria-label="Close" class="close-btn" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
    <p>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
      industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
      and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
      into electronic typesetting, remaining essentially unchanged.
    </p>

    <p>
      It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
      and more recently with desktop publishing software like Aldus PageMaker including versions
      of Lorem Ipsum.
    </p>

    <p>
      Why do we use it?
      It is a long established fact that a reader will be distracted by the readable content of a page when looking
      at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
      as opposed to using 'Content here, content here', making it look like readable English.
    </p>

    <p>
      Many desktop publishing packages and web page editors now use Lorem
      Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in
      their infancy.
    </p>
  </div>
</ng-template>

<ng-template #templateprivacy>
  <div class="modal-header">
    <h4 class="modal-title">Privacy</h4>
    <button (click)="modalRef2.hide()" aria-label="Close" class="close-btn" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
      industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
      and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
      into electronic typesetting, remaining essentially unchanged.
    </p>

    <p>
      It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
      and more recently with desktop publishing software like Aldus PageMaker including versions
      of Lorem Ipsum.
    </p>

    <p>
      Why do we use it?
      It is a long established fact that a reader will be distracted by the readable content of a page when looking
      at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
      as opposed to using 'Content here, content here', making it look like readable English.
    </p>

    <p>
      Many desktop publishing packages and web page editors now use Lorem
      Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in
      their infancy.
    </p>
  </div>
</ng-template>