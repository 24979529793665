<div class="main-section">
  <header>
    <div class="row">
      <div class="col-md-3">
        <div class="logo">
          <img src="assets/icon/tcc.png">
        </div>
        <div class="menu-btn" (click)="showMenu = !showMenu">
          <img src="assets/icon/menu.svg">
        </div>
      </div>
      <div class="col-md-9">
        <div class="icon-search-part">
          <ul>
            <!-- <li class="search-part">
                <div class="form-group">
                    <mat-form-field class="example-full-width" floatLabel="never">
                    <mat-label>Search</mat-label>
                    <input type="text" matInput placeholder="">
                    <mat-icon matPrefix><img src="assets/icon/search.svg"></mat-icon>
                    </mat-form-field>
                </div>
            </li> -->
<!--            <li><a [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" [routerLink]="'/chat'" href="javascript:void(0)"><img src="assets/icon/message.svg"></a></li>-->
<!--             <li><a href="javascript:void(0)"><img src="assets/icon/notification-white.svg"></a></li>-->
            <li><a [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" [routerLink]="'/manage'"  href="javascript:void(0)"><img src="assets/icon/person.svg"></a></li>
            <li><a (click)="authService.logout()" href="/"><img src="assets/icon/logout.svg"></a></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
  <div class="main-content"  [ngClass]="showMenu == false ? 'full-screen' : ''">
    <div class="menu-part">
      <div class="user-part">
        <div class="round-part">
          <img *ngIf="authDetail['isProfile']" [src]="'common/manage/user/view/'+this.authDetail['profileImageUrl'] | authImage | async">
          <span *ngIf="!authDetail['isProfile']">{{this.authDetail['profile']}}</span>
        </div>
        <div class="user-name">
          <h5>{{authDetail?.firstName}} {{authDetail?.lastName}}</h5>
          <p>{{authDetail?.designation}}</p>
        </div>
        <div class="option-icon">
        <!--  <ul>
            <li><a href="#"><img src="assets/icon/user-white.png"></a></li>
            <li><a href="#"><img src="assets/icon/user-white.png"></a></li>
          </ul>-->
        </div>
      </div>
      <app-menu></app-menu>
    </div>
    <router-outlet></router-outlet>
  </div>
  <div class="bg-design"></div>

  <footer>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-7">
          <div class="copyright">
            <p>@{{currentYear}} Tele Cardio Care. <span>All right reserved</span></p>
          </div>
        </div>
        <div class="col-md-5">
          <div class="foot-menu">
            <ul>
<!--              <li><a href="#">support</a></li>-->
<!--              <li><a href="#">terms</a></li>-->
<!--              <li><a href="#">privacy</a></li>-->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>
