declare var CryptRSA: any;

const PUBLIC_FORMAT = "public";
const PRIVATE_FORMAT = "pkcs8";


export function generatePublicAndPrivateKey(){
    let rsa = new CryptRSA({b:1024});
    const publicKey = rsa.exportKey(PUBLIC_FORMAT);
    const privateKey = rsa.exportKey(PRIVATE_FORMAT);
    return {
        publicKey,
        privateKey,
    }
}



export function encryptWithPublicKey(publicKey:string,encryptionData){
    let rsa = new CryptRSA(publicKey,PUBLIC_FORMAT);

    return rsa.encrypt(encryptionData,'base64');
}

export function decryptWithPrivateKey(privateKey:string,chiperCode:string){
    let rsa = new CryptRSA(privateKey,PRIVATE_FORMAT);
    return rsa.decrypt(chiperCode,`utf8`);
}
